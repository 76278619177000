import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import getBaseUrl from '../config';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from './LoadingSpinner'; // Import the LoadingSpinner component

function AdminLogin() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State for controlling the spinner
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Start spinner before the request
    fetch(`${getBaseUrl()}/api/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.jwtAccessToken && data.jwtRefreshToken) {
          // Store the access token and refresh token in localStorage
          localStorage.setItem('token', data.jwtAccessToken);
          localStorage.setItem('refreshToken', data.jwtRefreshToken);
          navigate('/admin'); // Navigate to admin dashboard
        } else {
          alert('Login failed');
        }
      })
      .catch(error => {
        console.error('Login error:', error);
        alert('Login failed');
      })
      .finally(() => setIsLoading(false)); // Stop spinner after the request completes
  };

  return (
    <div className="container mt-5">
      {isLoading && <LoadingSpinner />} {/* Show spinner when loading */}
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="text-center mb-4">{t('adminLogin')}</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="username">{t('username')}</label>
              <input
                type="text"
                className="form-control"
                id="username"
                value={username}
                onChange={e => setUsername(e.target.value)}
                placeholder="Enter username"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">{t('password')}</label>
              <input
                type="password"
                className="form-control"
                id="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                placeholder="Enter password"
                required
              />
            </div>
            <button type="submit" className="btn btn-primary btn-block mt-3" disabled={isLoading}>
              {isLoading ? 'Logging in...' : 'Login'} {/* Change button text based on loading state */}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminLogin;
