// Helper function to decode JWT
function decodeToken(token) {
    try {
      return JSON.parse(atob(token.split('.')[1])); // Decodes the payload of the token
    } catch (e) {
      return null;
    }
  }
  
  // Helper function to check token expiration
  function isTokenExpired(token) {
    const decoded = decodeToken(token);
    if (!decoded || !decoded.exp) return true; // Invalid token or no expiration
    const currentTime = Date.now() / 1000; // Current time in seconds
    return decoded.exp < currentTime; // Check if expired
  }
  
  // Helper function to get the token (and refresh if necessary)
 export default function getToken() {
    const token = localStorage.getItem('token');
    
    if (!token || isTokenExpired(token)) {
      // If no token or expired, refresh it
      return false
    }
    
    return token;
  }
  