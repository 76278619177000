import React from 'react';
import { useTranslation } from 'react-i18next';
import '../css/aboutme.css';

const AboutMe = () => {
  const { t } = useTranslation();

  return (
    <div className="aboutme-photo-container">
      <div className="image-section">
        <img src="/images/aboutme.jpg" alt="About me art" className="aboutme-image" />
      </div>
      <div className="content-section">
        <h2 className='aboutme-title'>{t('aboutmepage.title')}</h2>
        <p>{t('aboutmepage.description')}</p>
      </div>
      <div className="contact-section">
        <h4>{t('aboutmepage.contactTitle')}</h4>
        <ul>
          <li>
            <a href="https://www.instagram.com/quamiph/" target="_blank" rel="noopener noreferrer">
              {t('aboutmepage.instagram')}
            </a>
          </li>
          <li>
            <a href="mailto:cquadrosmm@gmail.com">{t('aboutmepage.email')}</a>
          </li>
          <li>{t('aboutmepage.location')}</li>
          <li>
            <a href="https://www.tiktok.com/@quamiph?_t=8qR0cS7HCWh&_r=1" target="_blank" rel="noopener noreferrer">
              {t('aboutmepage.tiktok')}
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default AboutMe;
