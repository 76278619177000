import React, { useState, useEffect, useCallback } from 'react';
import getBaseUrl from '../config';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; 
import '../css/photos.css';
import LoadingSpinner from './LoadingSpinner';

function Photos() {
  const [photos, setPhotos] = useState([]);
  const [auth, setAuth] = useState(null);
  const { t } = useTranslation();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [imageSizes, setImageSizes] = useState({}); 
  const [isLoading, setIsLoading] = useState(false);

  // Fetch photos with spinner control
  useEffect(() => {
    const filter = new URLSearchParams(location.search).get('filter');
    const fetchPhotos = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${getBaseUrl()}/api/photos/filter?category=${filter}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        setPhotos(data);
      } catch (error) {
        console.error('Failed to fetch photos:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPhotos();
  }, [location.search]);

  // Authenticate user with spinner control
  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');
    setAuth(token);

    const isTokenValid = (token) => {
      try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decoded.exp > currentTime;
      } catch (e) {
        return false;
      }
    };

    const authenticate = async () => {
      setIsLoading(true);
      try {
        if (token && isTokenValid(token)) {
          setIsAuthenticated(true);
        } else if (refreshToken && isTokenValid(refreshToken)) {
          const response = await fetch('/api/refresh-token', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ refreshToken }),
          });
          const data = await response.json();
          if (response.ok && data.token) {
            localStorage.setItem('token', data.token);
            setIsAuthenticated(true);
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            setIsAuthenticated(false);
          }
        } else {
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Token refresh failed:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };

    authenticate();
  }, []);

  const handleImageLoad = (e, index) => {
    const { naturalWidth, naturalHeight } = e.target;
    setImageSizes((prevSizes) => ({
      ...prevSizes,
      [index]: { width: naturalWidth, height: naturalHeight },
    }));
  };

  const handleDelete = (name, category) => {
    setIsLoading(true);
    fetch(`${getBaseUrl()}/api/photos/delete?name=${name}&category=${category}`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${auth}`,
      },
    })
      .then((response) => {
        if (response.ok) {
          setPhotos((prevPhotos) =>
            prevPhotos.filter((photo) => !(photo.name === name && photo.category === category))
          );
        } else {
          console.error('Failed to delete photo:', response.statusText);
        }
      })
      .catch((error) => console.error('Error deleting photo:', error))
      .finally(() => setIsLoading(false));
  };

  const handlePhotoClick = (index) => {
    setSelectedPhotoIndex(index);
  };

  const handleCloseModal = () => {
    setSelectedPhotoIndex(null);
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (selectedPhotoIndex !== null) {
        if (event.key === 'ArrowRight') {
          setSelectedPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
        } else if (event.key === 'ArrowLeft') {
          setSelectedPhotoIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
        }
      }
    },
    [selectedPhotoIndex, photos.length]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className="container my-5">
      {isLoading && <LoadingSpinner />} {/* Show spinner when loading */}
      <h1 className="text-center mb-4">{t('photoGallery')}</h1>
      <div className="masonry-gallery">
        {photos.map((photo, index) => {
          const isLandscape = imageSizes[index]?.width > imageSizes[index]?.height;
          return (
            <div
              key={photo.id}
              className={`masonry-item ${isLandscape ? 'wide' : 'portrait'}`}
              onClick={() => handlePhotoClick(index)}
              style={{ cursor: 'pointer' }}
            >
              {isAuthenticated && (
                <button
                  className="btn btn-danger position-absolute top-0 end-0 m-2"
                  style={{ zIndex: 1 }}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDelete(photo.name, photo.category);
                  }}
                >
                  X
                </button>
              )}
              <img
                src={`${getBaseUrl()}/${photo.url}`}
                className="card-img-top"
                alt={photo.name}
                onLoad={(e) => handleImageLoad(e, index)} // Handle image load to capture dimensions
              />
            </div>
          );
        })}
      </div>

      {selectedPhotoIndex !== null && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <button
              className="btn btn-secondary modal-nav-left"
              onClick={() =>
                setSelectedPhotoIndex((selectedPhotoIndex - 1 + photos.length) % photos.length)
              }
            >
              &larr;
            </button>
            <img
              src={`${getBaseUrl()}/${photos[selectedPhotoIndex].url}`}
              alt={photos[selectedPhotoIndex].name}
              className="modal-image"
            />
            <button
              className="btn btn-secondary modal-nav-right"
              onClick={() => setSelectedPhotoIndex((selectedPhotoIndex + 1) % photos.length)}
            >
              &rarr;
            </button>
            <button className="btn btn-danger close-modal" onClick={handleCloseModal}>
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Photos;