import React, { useState } from 'react';
import getBaseUrl from '../config';
import getToken from '../utils/token';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import LoadingSpinner from './LoadingSpinner'; // Import the LoadingSpinner component

function AdminDashboard() {
  const [file, setFile] = useState(null);
  const [name, setName] = useState('');
  const [category, setCategory] = useState('');
  const [isLoading, setIsLoading] = useState(false); // State for controlling the spinner
  const { t } = useTranslation();
  const navigate = useNavigate();

  // Define the enum for photo types
  const PhotoCategories = {
    COUPLE: 'Couple',
    SINGLE: 'Single',
    FAMILY: 'Family',
    KID: 'Kid',
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Start spinner before the upload begins

    const formData = new FormData();
    formData.append('file', file);
    formData.append('name', name);
    formData.append('category', category);

    const token = getToken();
    if (!token) {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      navigate('/login');
    }

    fetch(`${getBaseUrl()}/api/photos/upload`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`
      },
      body: formData,
    }).then(response => {
      if (response.ok) {
        alert('Photo uploaded successfully!');
      } else {
        alert('Photo upload failed');
      }
    }).catch(error => {
      console.error('Error uploading file:', error);
      alert('Photo upload failed');
    }).finally(() => {
      setIsLoading(false); // Stop spinner after the upload completes
    });
  };

  return (
    <div className="container mt-5">
      {isLoading && <LoadingSpinner />} {/* Show spinner when loading */}
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2 className="text-center mb-4">{t('uploadPhoto')}</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="file">{t('choosePhoto')}</label>
              <input
                type="file"
                className="form-control-file"
                id="file"
                onChange={e => setFile(e.target.files[0])}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">{t('photoName')}</label>
              <input
                type="text"
                className="form-control"
                id="name"
                value={name}
                onChange={e => setName(e.target.value)}
                placeholder={t('enterPhotoName')}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="category">{t('photoCategory')}</label>
              <select
                className="form-control"
                id="category"
                value={category}
                onChange={e => setCategory(e.target.value)}
                required
              >
                <option value="">{t('selectPhotoCategory')}</option>
                <option value={PhotoCategories.COUPLE}>{t('couple')}</option>
                <option value={PhotoCategories.SINGLE}>{t('single')}</option>
                <option value={PhotoCategories.FAMILY}>{t('family')}</option>
                <option value={PhotoCategories.KID}>{t('kid')}</option>
              </select>
            </div>
            <button
              type="submit"
              className="btn btn-success btn-block mt-3"
              disabled={isLoading} // Disable the button when loading
            >
              {isLoading ? t('uploading') : t('upload')} {/* Change button text when loading */}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
