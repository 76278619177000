import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Photos from './components/Photos';
import AdminLogin from './components/AdminLogin';
import AdminDashboard from './components/AdminDashboard';
import AboutMe from './components/AboutMe';
import ProtectedRoute from './components/ProtectedRoute'; 
import './App.css';
import PhotoCategories from './components/PhotosCategories';

function App() {
  return (
    <Router>
      <div className="app-container">
        <Header />
        <Routes>
          <Route path="/" element={<PhotoCategories />} />
          <Route path="/photos" element={<Photos />} />
          <Route path="/login" element={<AdminLogin />} />
          <Route path="/aboutme" element={<AboutMe />} />
          {/* Protect the admin route */}
          <Route 
            path="/admin" 
            element={
              <ProtectedRoute>
                <AdminDashboard />
              </ProtectedRoute>
            } 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
