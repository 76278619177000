import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import '../css/photoscategories.css';  // Import the CSS file

const PhotoCategories = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  // Define the categories and their associated images/text
  const categories = [
    { id: 1, label: t('single'), image: '/images/single.jpeg', category: 'Single' },
    { id: 2, label: t('couple'), image: '/images/couple.jpeg', category: 'Couple' },
    { id: 3, label: t('kid'), image: '/images/kid.jpeg', category: 'Kid' },
    { id: 4, label: t('family'), image: '/images/family.jpeg', category: 'Family' }
  ];

  // Handler function to navigate to the filtered photo page
  const handleCategoryClick = (category) => {
    navigate(`/photos?filter=${category}`);
  };

  return (
    <div className="container container-category"> 
      {categories.map((category) => (
        <div
          key={category.id}
          className="card"  // Apply className for the card
          style={{ backgroundImage: `url(${category.image})` }}
          onClick={() => handleCategoryClick(category.category)}
        >
          <div className="overlay"> 
            <div className="text-container">
              <span className="category-text">{category.label}</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PhotoCategories;
