import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Use named export

function ProtectedRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refreshToken');

    const isTokenValid = (token) => {
      try {
        const decoded = jwtDecode(token); // Use named export correctly
        const currentTime = Date.now() / 1000; // in seconds
        return decoded.exp > currentTime;
      } catch (e) {
        return false;
      }
    };

    const authenticate = async () => {
      if (token && isTokenValid(token)) {
        setIsAuthenticated(true);
      } else if (refreshToken && isTokenValid(refreshToken)) {
        try {
          const response = await fetch('/api/refresh-token', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refreshToken }),
          });
          const data = await response.json();

          if (response.ok && data.token) {
            localStorage.setItem('token', data.token);
            setIsAuthenticated(true);
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error('Token refresh failed:', error);
          setIsAuthenticated(false);
        }
      } else {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        setIsAuthenticated(false);
      }
    };

    authenticate();
  }, []);

  if (isAuthenticated === null) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default ProtectedRoute;
